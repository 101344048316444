import VueRouter from "vue-router";
import Vue from "vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/videos'
  },
  {
    path: '/videos',
    component: () => import('@/views/VideosView'),
    meta: {
      title: '视频列表'
    }
  },
  {
    path: '/upload',
    component: () => import('@/views/UploadView')
  }
];

const router = new VueRouter({
  routes,
});

export default router;
