<template>
  <div id="app">
    <div class="root">
      <el-container>
        <el-container class="body">
          <el-aside width="auto">
            <el-menu
              :default-active="$route.path"
              :router="true"
              active-text-color="#ffd04b"
              background-color="#545c64"
              text-color="#fff"
            >
              <el-menu-item index="/videos">
                <span slot="title">Video Playback</span>
              </el-menu-item>
              <el-menu-item index="/upload">
                <span slot="title">Video Upload</span>
              </el-menu-item>
            </el-menu>
          </el-aside>
          <el-main>
            <keep-alive include="UploadView">
              <router-view/>
            </keep-alive>
          </el-main>
        </el-container>
      </el-container>
    </div>
  </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style>
* {
  margin: 0;
}

.el-main {
  min-height: 100vh;
  width: 80vw;
}

.el-aside {
  width: 20vw;
}
</style>
